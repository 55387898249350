/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
       { title: 'Negociar', route: { name: 'negociacao' }, icon: 'DollarSignIcon', isBookmarked: true },
      //  { title: 'Chat', route: { name: 'apps-chat' }, icon: 'MessageSquareIcon', isBookmarked: true },
       { title: 'Acompanhamento', route: { name: 'acompanhamento' }, icon: 'ActivityIcon',  isBookmarked: true },
    ],
  },
}
/* eslint-enable */
