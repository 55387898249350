<template>
  <b-navbar-nav
    v-if="load"
    class="nav"
  >
    <b-nav-item
      v-for="(bookmark, index) in bookmarks"
      :id="`bookmark-${index}`"
      :key="index"
      :to="bookmark.route"
      @click="checkRoute"
    >
      <feather-icon
        v-if="active === bookmark.route.name"
        :icon="bookmark.icon"
        size="21"
        style="color: #836FFF"
      />
      <feather-icon
        v-else
        :icon="bookmark.icon"
        size="21"
      />
      <b-tooltip
        triggers="hover"
        :target="`bookmark-${index}`"
        :title="bookmark.title"
        :delay="{ show: 1000, hide: 50 }"
      />
    </b-nav-item>

  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import router from '@/router'
import cobranca from './bookmarks/cobranca'
// import admin from './bookmarks/admin'

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip,
  },
  setup() {
    const permissoesJson = localStorage.getItem('permissoes')
    let pages = {
      pages: {
        data: [],
      },
    }
    if (permissoesJson) {
      const permissoes = JSON.parse(permissoesJson)
      const { chat } = permissoes
      // pages = cobranca
      if (chat) {
        if (chat['chat.index'].permissao) {
          pages = cobranca
        }
      }
    }
    pages = cobranca
    // switch (grupoAcess) {
    //   case '1':
    //     pages = admin
    //     break
    //   case '5':
    //     pages = cobranca
    //     break
    //   case '6':
    //     pages = cobranca
    //     break
    //   default:
    //     break
    // }
    // const searchAndBookmarkDataPages = ref(searchAndBookmarkData.pages)
    const bookmarks = ref(pages.pages.data.filter(page => page.isBookmarked))
    return {
      bookmarks,
    }
  },
  data() {
    return {
      active: '',
      load: true,
      grupo_acess: null,
      pages: {},
      isCredor: localStorage.getItem('credores'),
    }
  },
  mounted() {
    this.checkRoute()
  },
  methods: {
    async checkRoute() {
      if (router.app.$route.name) {
        this.load = false
        this.active = router.app.$route.name
        this.load = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
  position: fixed;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;

  &:not(.show) {
    pointer-events: none;
  }

  &.show {
    cursor: pointer;
    z-index: 10;
    opacity: 1;
  }
}
</style>
